/* eslint-disable react/prop-types */
import { Stack, StackProps } from '@mui/material';
import { LayoutFooter } from '../Footer';
import { LayoutSidebarLogo } from './Logo';

import logoImg from 'common/assets/logo-otimizada.png';

type Props = StackProps & {
  isLargeSidebar: boolean;
};

const LayoutSidebar: React.FC<Props> = ({
  isLargeSidebar,
  children,
  sx,
  ...props
}) => {
  return (
    <Stack
      spacing={isLargeSidebar ? 4 : 2}
      sx={
        isLargeSidebar
          ? {
              width: 250,
              padding: 3,
              paddingRight: 0,
              transition: 'all 0.3s ease-in-out',
              ...sx,
            }
          : {
              width: 'auto',
              padding: 3,
              paddingRight: 0,
              paddingLeft: 0,
              transition: 'all 0.3s ease-in-out',
              ...sx,
            }
      }
      {...props}
    >
      <LayoutSidebarLogo
        image={logoImg}
        name='Logo'
        size={isLargeSidebar ? 'large' : 'small'}
      />

      {children}

      <LayoutFooter size={isLargeSidebar ? 'medium' : 'small'} />
    </Stack>
  );
};

export { LayoutSidebar };
