import {
  HiOutlineDocumentText,
  HiOutlineOfficeBuilding,
  HiOutlineShieldCheck,
  HiOutlineUserCircle,
  HiOutlineUsers,
  HiOutlineVideoCamera,
  HiOutlineViewGrid,
} from 'react-icons/hi';
import { IconType } from 'react-icons/lib';
import { MdOutlineSettingsPower } from 'react-icons/md';

export type IMenuLink = {
  path: string;
  name: string;
  permissions?: string[];
  Icon: IconType;
  premium?: boolean;
  hasSearch?: boolean;
  subLinks?: Array<{
    path: string;
    name: string;
    permissions?: string[];
    Icon: IconType;
    premium?: boolean;
    hasSearch?: boolean;
  }>;
};

export type IMenu = {
  groupName: string;
  links: IMenuLink[];
};

export const menus: IMenu[] = [
  {
    groupName: 'Geral',
    links: [
      {
        path: '/',
        name: 'Painel de Controle',
        Icon: HiOutlineViewGrid,
      },
      {
        path: '/access-logs',
        name: 'Logs de Acesso',
        Icon: HiOutlineDocumentText,
      },
      {
        path: '/companies',
        name: 'Empresas',
        Icon: HiOutlineOfficeBuilding,
        hasSearch: true,
      },
      {
        path: '/users',
        name: 'Usuários',
        Icon: HiOutlineUsers,
        hasSearch: true,
      },
      {
        path: '/videos',
        name: 'Vídeos',
        Icon: HiOutlineVideoCamera,
        hasSearch: true,
      },
    ],
  },
  {
    groupName: 'Configurações',
    links: [
      {
        path: '/profile',
        name: 'Perfil',
        Icon: HiOutlineUserCircle,
      },
    ],
  },
  {
    groupName: 'Sistema',
    links: [
      {
        path: '/sign-out',
        name: 'Sair',
        Icon: MdOutlineSettingsPower,
      },
    ],
  },
];
