import { Avatar, AvatarProps, Box } from '@mui/material';

const contentSize = {
  small: 40,
  medium: 96,
  large: 164,
};

type Props = AvatarProps & {
  image: string;
  name: string;
  size?: 'small' | 'medium' | 'large';
};

const LayoutSidebarLogo = ({
  image,
  name,
  size = 'large',
  sx,
  ...rest
}: Props) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Avatar
        src={image}
        alt={name}
        title={name}
        sx={{
          margin: size === 'large' ? 2 : 1,
          height: size === 'large' ? 60 : 'auto',
          width: contentSize[size],
          borderRadius: 0,
          '& img': { objectFit: 'contain', objectPosition: 'center' },
          ...sx,
          background: 'transparent',
          color: (theme) => theme.palette.secondary.dark,
          textAlign: 'center',
          fontSize: [12, 16],
          fontWeight: 'bold',
        }}
        {...rest}
      >
        {name}
      </Avatar>
    </Box>
  );
};

export { LayoutSidebarLogo };
