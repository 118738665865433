import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  StackProps,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import {
  HiOutlineMenu,
  HiOutlineMenuAlt3,
  HiOutlineUserCircle,
  HiUser,
  HiX,
} from 'react-icons/hi';
import { MdOutlineSettingsPower } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { UserType } from 'common/types';
import { LayoutSidebarLogo } from '../Sidebar/Logo';
import { LayoutHeaderContent } from './Content';

import logoImg from 'common/assets/logo-otimizada.png';

type Props = StackProps & {
  user: UserType | null;
  signOut: () => void;
  onToggleMenu: () => void;
  isOpenMenu: boolean;
};

const LayoutHeader: React.FC<Props> = ({
  user,
  signOut,
  onToggleMenu,
  isOpenMenu,
  children,
  sx,
  ...props
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:650px)');
  const isLimitButtonText = useMediaQuery('(max-width:900px)');

  const [openMenuUser, setOpenMenuUser] = React.useState(false);

  const handleOpenMenuUser = () => {
    setOpenMenuUser(true);
  };

  const handleCloseMenuUser = () => {
    setOpenMenuUser(false);
  };

  const handleClickUser = () => {
    if (isMobile) {
      handleOpenMenuUser();
    } else {
      navigate('/profile');
      return;
    }
  };

  return (
    <>
      <Stack
        spacing={1}
        direction='row'
        className='base-box-shadow'
        {...props}
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          height: 64,
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: 2,
          position: ['relative', 'sticky'],
          paddingX: isMobile ? 2 : 0,
          top: 0,
          zIndex: 2,
          ...sx,
        }}
      >
        <Box display='flex' height='100%' alignItems='center'>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={
              !isMobile
                ? {
                    borderRight: `2px solid ${grey[200]}`,
                    marginRight: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                  }
                : {}
            }
          >
            <IconButton title='Menu' color='secondary' onClick={onToggleMenu}>
              {isOpenMenu ? <HiOutlineMenuAlt3 /> : <HiOutlineMenu />}
            </IconButton>
          </Box>

          {!isMobile && <LayoutHeaderContent>{children}</LayoutHeaderContent>}
        </Box>

        {isMobile && (
          <LayoutSidebarLogo image={logoImg} name='Logo' size='medium' />
        )}

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
          spacing={2}
          sx={{ paddingRight: !isMobile ? 2 : 0 }}
        >
          <Button
            color='secondary'
            title='Informações'
            onClick={handleClickUser}
            sx={{
              color: (theme) => theme.palette.secondary.main,
              display: 'inline-flex',
              alignItems: 'center',
              minWidth: 'auto',
            }}
          >
            <HiOutlineUserCircle size='24px' />
            {!isMobile && (
              <Typography
                variant='body2'
                noWrap
                sx={{
                  fontWeight: 'bold',
                  marginLeft: 1,
                  width: '100%',
                  maxWidth: isLimitButtonText ? 100 : '100%',
                }}
              >
                {user?.name} {user?.role && `(${user?.role.name})`}
              </Typography>
            )}
          </Button>
          {!isMobile && (
            <IconButton
              title='Sair'
              color='secondary'
              onClick={() => signOut()}
            >
              <MdOutlineSettingsPower size='24px' />
            </IconButton>
          )}
        </Stack>
      </Stack>

      {isMobile && (
        <LayoutHeaderContent sx={{ paddingX: 3, paddingY: 2 }}>
          {children}
        </LayoutHeaderContent>
      )}

      <SwipeableDrawer
        anchor='right'
        open={openMenuUser}
        onClose={handleCloseMenuUser}
        onOpen={handleOpenMenuUser}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          },
          '& .MuiPaper-root': {
            width: 250,
            backgroundColor: 'secondary.dark',
            color: 'secondary.light',
          },
        }}
      >
        <Stack
          sx={{
            height: '100%',
            justifyContent: 'space-between',
            padding: 3,
            paddingTop: 5,
          }}
        >
          <Stack spacing={2} onClick={handleCloseMenuUser}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ color: 'inherit' }}>Informações</Typography>

              <IconButton color='secondary' onClick={handleCloseMenuUser}>
                <HiX />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} />

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant='text'
                color='secondary'
                startIcon={<HiUser />}
                onClick={() => navigate('/profile')}
              >
                <Typography
                  variant='body2'
                  sx={{
                    fontWeight: 'bold',
                    marginLeft: 1,
                    color: 'inherit',
                  }}
                >
                  {user?.name} {user?.role && `(${user?.role.name})`}
                </Typography>
              </Button>
            </Box>
          </Stack>

          <Button
            variant='text'
            color='secondary'
            endIcon={<MdOutlineSettingsPower size='24px' />}
            onClick={() => signOut()}
            sx={{
              color: (theme) => theme.palette.secondary.main,
              display: 'inline-flex',
              alignItems: 'center',
              minWidth: '100%',
              backgroundColor: '#1b1c1d',
              justifyContent: 'space-between',
              padding: '16px 24px',
            }}
          >
            Sair
          </Button>
        </Stack>
      </SwipeableDrawer>
    </>
  );
};

export { LayoutHeader };
