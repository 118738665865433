import { LinearProgress } from '@mui/material';
import { AuthLayout } from 'layouts/AuthLayout';
import { MuiLayout } from 'layouts/MuiLayout';
import { lazy, Suspense } from 'react';
import { Route, Routes as ContainerRoutes } from 'react-router-dom';
import { NoRequireAuth } from './NoRequireAuth';
import { RequireAuth } from './RequireAuth';

const PageNotFound = lazy(() => import('../modules/error/pages/NotFound'));
const PageProfile = lazy(() => import('../modules/dashboard/pages/Profile'));
const PageHomeView = lazy(() => import('../modules/dashboard/pages/Home'));
const PageCompanyList = lazy(() => import('../modules/company/pages/Company'));
const PageUserList = lazy(() => import('../modules/user/pages/List'));
const PageVideoList = lazy(() => import('../modules/video/pages/List'));
const AccessLogPageLogs = lazy(() => import('../modules/accessLog/pages/Logs'));
const PageUserForm = lazy(() => import('../modules/user/pages/Form'));
const PageAuthSignIn = lazy(() => import('../modules/auth/pages/SignIn'));
const PageAuthResetPassword = lazy(
  () => import('../modules/auth/pages/ResetPassword')
);
const PageAuthForgotPassword = lazy(
  () => import('../modules/auth/pages/ForgotPassword')
);

export function Routes() {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ContainerRoutes>
        {/* Routes Protected */}
        <Route
          path='/'
          element={
            <RequireAuth>
              <MuiLayout />
            </RequireAuth>
          }
        >
          <Route index element={<PageHomeView />} />

          <Route path='/profile' element={<PageProfile />} />

          <Route path='/access-logs' element={<AccessLogPageLogs />} />

          <Route path='/companies' element={<PageCompanyList />} />

          <Route path='/users' element={<PageUserList />} />
          <Route path='/users/create' element={<PageUserForm />} />
          <Route path='/users/:id' element={<PageUserForm />} />

          <Route path='/videos' element={<PageVideoList />} />
        </Route>

        {/* Routes Public */}
        <Route
          path='/'
          element={
            <NoRequireAuth>
              <AuthLayout />
            </NoRequireAuth>
          }
        >
          <Route path='/login' element={<PageAuthSignIn />} />
          <Route path='/forgot-password' element={<PageAuthForgotPassword />} />
          <Route path='/reset-password' element={<PageAuthResetPassword />} />
        </Route>

        <Route path='*' element={<PageNotFound />} />
      </ContainerRoutes>
    </Suspense>
  );
}
